@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
html, body{ font-size:15px; background: #eaeded; font-family: 'Poppins', sans-serif !important; line-height: 22px; background:#f5f6f9;/*new*/}
.h1, h1 {font-size: 34px; font-weight:800;}
.h3, h3 {font-size: 28px; margin: 0; font-weight:800; color: #6f7178;}
.mb-10{margin-bottom:12px}
.block-radius {border-radius: 8px!important;}
.bold {font-weight:bold!important;}
header{	background: #31af68; padding: 20px 0}
.logolink a,.logolink a:hover{ text-decoration: none; }
footer{ background: #efefef; padding: 60px 0 20px; border-top: 1px solid #757474; }
footer h4{ font-size: 15px; margin-top: 0; text-transform: uppercase; font-weight: bold; color: #000 }
footer p{ font-size: 12.5px; color: #000 }
footer a{ text-decoration: none !important; color: #000; font-size: 12.5px  }
img{
	width: 100%;
	height: auto;
}
.psl{
	position: relative;
}
.pt-5{
	padding-top: 5px !important;
}
.pd105{
	padding:10px 5px;
}

	.text-yellow {
		color: #31af68;
	}

.mt-5{ margin-top: 5px;}
.mt-9{ margin-top: 9px;}
.mt-10{ margin-top: 10px;}
.mt-15{ margin-top: 15px;}
.mt-20{ margin-top: 20px;}
.mt-30{ margin-top: 30px;}
.mt-35{ margin-top: 35px;}
.mt-40{ margin-top: 40px;}
.mt-50{	margin-top: 50px;}
.mt-70{	margin-top: 70px;}
.mt-80{	margin-top: 80px;}
.mt-100{ margin-top: 100px; }
.mt-110{ margin-top: 110px; }
.mt-300{ margin-top: 300px; }
.mt-130{ margin-top: 130px; }
.mb-30{	margin-bottom: 30px;}
.mb-50{	margin-bottom: 50px;}
.mb-100{ margin-bottom: 100px;}
.pl-0{padding-left: 0;}
.pr-0{padding-right: 0;}
.p-0{padding: 0}
.border-right{border-right:1px solid #31af68;}
.text-white{color: #fff !important;}
.text-red{color: #ee4a48;}
.text-black{ color: #000 !important; }
.text-blue{	color: #102851;}
.text-dblue{ color: #012971;}
.text-color1{ color: #1f8f07; color:#6f7178;/*new*/}
.text-color2{ color: #ccc; }
.text-color3{ color: #829a7f; }
.text-bold{	font-weight: bold;}
.text-right{ text-align: right; }
.text-left{ text-align: left; }
.font-10{ font-size: 14px !important; line-height:1.14em;}
.font-14{ font-size: 14px; }
.font-16{ font-size: 16px; }
.font-24{ font-size: 22px; line-height: 1.2em;}
.mt-26{ margin-top:26px; }
.pdr1{ padding-right: 0px; }
.fl-r{ float: right !important; }
.fw-700{
	font-weight: 700;
	margin-bottom: 0px;
}
.mrb-5{
	margin-bottom: 5px !important;
}
.resentBtn{
	border: none;
    background: transparent;
    text-transform: uppercase;
    
}
.cartno{ font-size: 11px;
    background: #fbcb2d;
    position: relative;
    top: -8px;
    padding: 0px 3px;
    border-radius: 3px;}
	.addres-modal-body{
		position: relative;
    flex: 1 1 auto;
    padding: 0px 40px 60px;
	}
	.addres-modal-body h6{
		font-weight: 600;
    margin-bottom: 12px;
}
.ft-12{
	font-size: 12px;

}
.mrt-4{
	margin-top: 4px;
}
.ft-12 img{
	width: 21px !important;
    margin-right: 5px;
}
.addres-modal-body button{
	background-color: #31af68;
	border: #31af68;
}
	
.hero{ padding:30px 0 20px 0; }
/* The container must be positioned relative: */
.category-select {
	position: relative;
	font-family: Arial;
	font-size: 16px !important;
  }
  .content-list{
	  max-height: 0;
	  overflow: hidden;
      transition: all 0.5s cubic-bezier(0,1,0,1); 
	  /* transition: all 200ms ease-in-out; */
  }
  .content-list.show{
	  height: auto;
	  max-height: 9999px;
	  /* transition: all 0.5s cubic-bezier(1,0,1,0); */
	  /* transition: all 200ms ease-in-out; */
  }
  .category-select select {
	display: none; /*hide original SELECT element: */
  }
  
  .select-selected {
	background-color: DodgerBlue;
  }
  .pb-20 {
    padding-bottom: 20px;
}

.pt-20 {
    padding-top: 20px;
}
  /*Explosionweb css */
  .mh50{
	  min-height: 50px !important;
  }
  .mh50 img{width: 25px !important; margin-right: 5px;}
  .help-error{
	font-size: 12px;
    color: #c51616;
  }
  .header-first-bar{
	display: flex;
    align-items: center;
  }
  .no-justify-content{
	  justify-content: unset !important;
  }
  .fg1{
	  flex-grow: 1;
  }
  .user-location .addLocatorBtn i{
	font-size: 23px;
    margin-right: 7px;
    color: #31af68;
  }
  .user-location .addLocatorBtn{
	border: none;
    font-size: 12px;
    padding: 6px;
    margin-right: 7px;
    border-radius: 5px;
	display: flex;
	align-items: center;

  }
  .user-location .addLocatorBtn p{
	  line-height: 11px;
	  font-size: 11px;
	  text-align: left;
	  margin-bottom: 0;
  }
  .modelLocation{
	  max-width: 400px;
  }
  .remove-wishlist {
   position: absolute;	
   right: 10px;
}
.remove-wishlist button{
	border: none;
	font-size: 12px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	background-color: rgb(239, 239, 239);
}
.isWishlisted{
	color: #f5f6f9;
    background: #31af68;
    border: none;
    font-size: 10px;
    text-align: center;
    width: 100%;
    min-width: 50px;
    border-radius: 3px;
	height: 24px;
}
.wish-container{
	height: calc(100vh - 150px);
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.mb-15{
	margin-bottom: 15px !important;
}
.brd-0{
	border-bottom: 0px;
}
.wishItem-not-found{
	min-height: 475px;
	display: flex;
	align-items: center;
	justify-content: center;

}
.wishlist-inner-not-found{
	max-width: 450px;
	text-align: center;
}
.wishlist-inner-not-found h3{
	font-size: 18px;
	color: #000;
    margin-bottom: 10px;
}
.wishlist-inner-not-found a, .wishlist-inner-not-found a:hover{
	max-width: 200px;
    margin: 0 auto;
	background: #1f5407;
    color: #fff;
    padding: 10px 15px;
    border-radius: 7px;
    display: block;
    text-decoration: none !important;
    font-size: 12px;
}
.wish-container .loader{
position: absolute;
    right: 0;
    left: 0;
    background: #cccfcd7a;
    width: 100%;
    height: 100%;
    z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
  .no-cart-inner{
	max-width: 256px;
    margin: 50px auto;
    text-align: center;
  }
  .no-cart-inner h3{
	font-size: 19px;
    margin-bottom: 5px;
	color: #000;
  }
  .no-cart-inner img{
	max-width: 155px;
  }
  .tbtn{
	  background: transparent;
	  box-shadow: none;
	  outline: none;
	  border: none;
  }
  .inputStyle {
	
		width: 40px !important;
		height: 40px;
	
  }
  .otp-box{
	  text-align: center;
  }
  .otp-box div:nth-child(1){
	  align-items: center;
	  justify-content: center;
  }
  .btn-row{
	  padding: 10px 0px;
  }
  .error {
	border: 1px solid red !important;
  }
  .btn-row .btn{
	  
	  cursor: pointer;
	  padding: 2px 10px;
  }
  .clear-btn{
	background-color: red;
	  margin-right: 10px;
	  color: #fff;
	  font-size: 12px;
  }
  .sb-btn{
	background-color: #007bff;
	color: #fff;
	font-size: 12px;
  }
.slider{
	position: relative;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #dedcdd !important;
    padding:40px;
    border-radius: 6px;
}
.slider .image{
	width: 100%;
	height: 450px;
	
}
.slider .right-arrow{
	position: absolute;
	top: calc(50% - 39px);
	right: 0px;

	color: #000;
	z-index: 10;
	cursor: pointer;
	user-select: none;
}
.slider .left-arrow{
	position: absolute;
	top: calc(50% - 39px);
	left: 0px;
	
	color: #000;
	z-index: 10;
	cursor: pointer;
	user-select: none;
}
.slider .left-arrow i{
	font-size: 18px;
    color: #fff;
    background: #1f8f07;
    padding: 30px 15px;
    border-radius: 0 6px 6px 0;
}
 .slider .right-arrow i{
	font-size: 18px;
    color: #fff;
    background: #1f8f07;
    padding: 30px 15px;
    border-radius: 6px 0px 0px 6px;
}
.slide{
	opacity: 0;
	transition-duration: 1s ease;
}
.slide.active{
	opacity: 1;
	transition-duration: 1s;
	transform: scale(1.08);
}

.product_specification .price{
  display: flex;
}
.product_specification .price p{
	font-size: 25px;
	font-weight: 600;
}
.main_price p{
	margin-right: 50px;
}
.pack-sizes h4{
	font-size: 16px;
}
.pack-sizes .form-check{
	background-color: #716F9B1F;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

}
.vname{
	margin-right: 15px;
}
.vprice{
	font-size: 14px;
    font-weight: 700;
}
.pack-sizes .form-check .form-check-label{
	margin-bottom: 0;
    width: 100%;
    height: 100%;
    padding: 10px 0px 10px 19px;
	display: flex;
	align-items: center;
   cursor: pointer;
}
.qty-add{
	display: flex;
    align-items: center;
    border: 1px solid #00000017;
    margin-top: 5px;
    justify-content: space-between;
}
.qty-add .qty-btn{
	padding: 5px;
    width: 25px;
	height: 25px;
    font-size: 16px !important;
	line-height: 16px;
    background: #fbcb2d;
    color: #000;
	border: none;

}
.qty-dropdown {
    border: 1px solid #00000029;
    border-radius: 0px;
    font-size: 12px;
    padding: 5px;
    width: 100%;
	outline: none;
}
.qty-dropdown:focus{
	outline: none;
	border: none;
}
.product-item .dropdown-menu{
	min-width: unset !important;
    width: 100%;
    border-radius: 0px;
    margin: 0;
	font-size: 12px;
    padding: 0;
    border: 1px solid #00000029;
	cursor: pointer;
}
.product-item .dropdown-menu .dropdown-item{
	padding: 4px 10px;
}

.qty-num{
	padding: 0px;
	width: 62px;
	text-align: center;
	overflow: hidden;
}
.qty-add input{
	width: 42px;
    height: 42px;
    margin-right: 13px;
    font-size: 16px;
    text-align: center;
}
.add-cart-product{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.product_specification .qty-add{
  max-width: 150px;
}
.product_specification .addtocart{
	background: #1f8f07;
    color: #fff;
    padding: 7px 30px;
    margin-left: 5px;
    border-radius: 6px;
    flex-grow: 1;
    border: none;

}
.form-check-input{
	margin-left: 0px !important;
	margin-top: 0;
}

  .cartp{
	  margin-top: 20px;
  }


.continue-shop{
	max-width: 200px;
    margin: 0 auto;
    background: #1f5407;
    color: #fff;
    padding: 10px 15px;
    border-radius: 7px;
    display: block;
    text-decoration: none !important;
    font-size: 12px;
}
.continue-shop:hover{
	color: #fff;
}
  .disc_sec h4, .sidebar1 h4 a{
color: #6f7178;
font-size: 18px;
text-decoration: none;
  }
  .sidebar1 li a.active{
	  color: #31af68;
  }
  .disc_sec label{
	  padding-left: 10px;
	  font-size: 14px;
  }
  .disc_sec input{
	  margin-right: 10px;
  }
  .sidebar1 ul{
	  list-style: none;
	  padding-left: 10px;
  }
  .sidebar1 ul li a{
	color: #000;
	font-size: 14px;
	text-decoration: none;
  }
  .not-found-box p i{
	  margin-right: 10px;
  }
  .not-found-box p{
	color: #9b2424;
  }
  .panel-title a{
	color: #6f7178;
  }
  .subsubcategory{
	  list-style: none;
	  padding-left: 5px;
  }
  .subsubcategory li{
	  padding: 3px 0px;
  }
  .subsubcategory li a{
	  color: #6f7178;
	  text-decoration: none;
  }
  .subsubcategory li a:hover{
	  color: #31af68;
  }
  .loading-container{
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  min-height: calc(100vh - 250px);
  }
  .loading-container img{
	  width: 50px;
  }
  .brand_image{
	/* max-width: 148px; */
	width: auto;
    max-height: 60px;
  }
  .dealBtn::after{
	 
	color: #31af68;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
	background: #1c854a;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #31af68;
  }
#payment-form iframe .pay-body{
	padding: 0px !important;
}
/******LOading css*********/
.mxw-64{
	max-width: 64px !important;
}
.resp-box h1{
	font-size: 18px;
    margin: 10px 0px 25px 0px;
}
.resp-box a{
	margin:0 auto;
}
.lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
}
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 22px;
    height: 22px;
    margin: 0px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  .lds-dual-ring-2 {
    display: inline-block;
    width: 25px;
    height: 25px;
}
  .lds-dual-ring-2:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 0px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #31af68 transparent #31af68 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
/******End**************/
/*******Payment Page css******/
.plr{
	position: relative;
}
.card-type{
	position: absolute;
    
	
    
}
.card-type-visa{
	background-image: url('../../../public/images/bank/card/visa.webp');
	background-repeat: no-repeat;
    background-size: cover;
    width: 55px;
    height: 55px;
    display: block;
	background-position-y: -8px;
	top: 30px;
    right: 20px;
}
.card-type-maestro{
	background-image: url('../../../public/images/bank/card/maestro.webp');
	background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    display: block;
	top: 31px;
    right: 8px;
}
  /* .page-title h1{
	  margin-top: 20px;
  } */
  .productlists{
	  width: 100%;
  }
  .productlists .listprice{
	margin: 0;
    color: #293341;
    line-height: 1.24em;
    margin-bottom: 4px;
    font-size: 13px;
    margin-top: 5px;
  }
  .pd7{
	  padding: 7px !important;
  }
  .visible-xs{
	  display: none;
  }
  .menu2{
	display: block;
}
  .dealBtn {
    display: flex;
    padding: 5px 6px;
    min-width: 90px;
    font-size: 14px;
    background: #fff;
    align-items: center;
    justify-content: center;
}
.payUsing{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.mb-0{
	margin-bottom: 0px !important;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dd-list{
	position: absolute;
	top: 34px;
	
}
.ft-unset{
font-size: unset !important;
position: relative;
}
.dd-list.dropdown-menu{
display: block !important;
padding: 0;

}
.dd-list li {
	border-bottom: 1px solid #cbcbcb57;
}
.dd-list li a{
width: 100%;
display: block;
    color: #555;
    text-decoration: none;
    width: 100%;
    padding: 5px 15px;
    font-size: 14px;

}
.dp-toggle{
	display: none;
}
.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-toggle .icon-bar {
    background: #1f8f07;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}
  /****End***********/
  .owl-carousel2 .owl-item, .owl-carousel22 .owl-item{
	padding: 10px;
  }
  .owl-carousel2 .owl-item img, .owl-carousel22 .owl-item img{
	  border-radius: 6px;
  }

  /* Style the arrow inside the select element: */
  .select-selected:after {
	position: absolute;
	content: "";
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #fff transparent transparent transparent;
  }
  
  /* Point the arrow upwards when the select box is open (active): */
  .select-selected.select-arrow-active:after {
	border-color: transparent transparent #fff transparent;
	top: 7px;
  }
  
  /* style the items (options), including the selected item: */
  .select-items div,.select-selected {
	color: #ffffff;
	padding: 8px 16px;
	border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
  }
  
  /* Style items (options): */
  .select-items {
	position: absolute;
	background-color: DodgerBlue;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
  }
  
  /* Hide the items when the select box is closed: */
  .select-hide {
	display: none;
  }
  
  .select-items div:hover, .same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
  }
.owl-item li{ list-style: none;}
.owl-carousel1{ padding-left: 0;}
.owl-carousel1 .owl-next{position: absolute; top:81%; right:15px;}
.owl-carousel1 .owl-prev{position: absolute; top:81%; right:40px;}
.owl-carousel2{ padding: 10px 20px; }
.owl-carousel2 .owl-next{position: absolute; top:37%; right:0px;}
.owl-carousel2 .owl-prev{position: absolute; top:37%; left: 0px;}
.owl-carousel2 .owl-item li{ list-style: none; padding: 10px; }
.owl-carousel22{ padding: 20px 20px 10px; }
.owl-carousel22 .owl-next{position: absolute; top:35%; right:0px;}
.owl-carousel22 .owl-prev{position: absolute; top:35%; left: 0px;}
.owl-carousel22 .owl-item li{ list-style: none; padding: 10px; }
.owl-carousel3{ padding-left: 0; padding-bottom: 15px;}
.owl-carousel3 .owl-next{position: absolute; top:-12%; right:1px;}
.owl-carousel3 .owl-prev{position: absolute; top:-12%; right:27px;}
.owl-carousel3 .owl-item li{ list-style: none; padding: 1px; }

.menu{ background: #cdcdcd; padding-top: 10px; padding-bottom:10px;}
.breadcrumbs{ padding: 10px 0 10px 0; border-bottom: 1px solid #1f8e07; background: #fff; }
.breadcrumbs h1{
	margin-bottom: 0px;
}
.breadcrumbs2{padding-top: 10px; border-bottom: 1px solid #e7e8e6; background: #f9f9f9; }
.breadcrumbtext a,.breadcrumbtext{color:#1f8e07; font-size: 16px;}
.pdetails{ padding: 30px 0; }
.product-title{font-size: 28px; margin: 0; font-weight: bold; color: #1f8e07}
.logo{ font-size: 50px; margin: 0 }
.spc1{ font-size: 14px; }
.logspan{ background: #fbfaf6; padding: 9px 0 8px 0;}
.logspan a{ text-decoration: none; font-size: 14px; padding: 0 14px}
.logspan i{ color: #31af68; }
.menu2{ padding-left: 0; margin-bottom: 0px; margin-top: -5px }
.menu2 li{ list-style: none; display: inline; }
.menu2 li a{ text-decoration: none; color: #000; padding: 7px 8px ; font-size: 14px; display: inline-block; }
.menu2 li a:hover{background:#31af68; color: #fff;}
.ddmenu span{ color: #000; }
.ddmenu:hover span{ color: #fff; }
.cat-product{ padding-bottom: 40px; }
.cat-product a{ text-decoration: none; }
.acc1{ padding-left: 0; 	 }
.acc1 li{ list-style: none; font-size: 14px; }
.acci1{ float: right; }
.accmenu1{ padding-left: 0; }
.accmenu1 li { list-style: none; }
.sidebar1{ background: #fff; padding:20px; border-radius:6px; }
.products{ padding-left: 0; }
.products li{ list-style: none; display: inline; float: left; width: 20%; padding: 0 10px }
.products li:first-child{margin-left:-10px;}
.products p{ font-size: 13px; }
.products p a{ text-decoration: none; }
.product-item{ background: #fff; padding: 8px; border: 1px solid #cbcdc8; border-radius: 6px;}
.product-item img{ width: 100%; }
.product-item p{ margin: 0; color: #293341; line-height: 1.24em; margin-bottom: 4px; margin-top: 4px;}
.product-name{ font-size: 14px; color: #293341; }
.product-item span.text-color1{font-size: 12px; color: #8d8d8d;}
.pay1{ padding-left: 0; }
.pay1 li{ list-style: none; display: inline; float: left; }
.app1{ padding-left: 0; margin-top: 22px; }
.app1 li{ list-style: none; display: inline; float: right; }
.footerc1{ padding-left: 0; }
.footer-logo{
	text-align: center;
}
.footer-logo img{
	max-width: 179px;
    margin-top: -16px;
}
.footerc1 li{ list-style: none; }
.footerc1 li a,.footerc1 p{ font-size: 15px; color: #000; }
.social{ padding-left: 0; text-align: center; }
.social li{ list-style: none; display: inline; padding: 0 5px; }
.social i{ font-size: 20px; color: #a0a0a0; }
.pdetails{ background: #fff; margin-bottom: 30px; }
.productdetails1{ padding-left: 0; }
.productdetails1 li{list-style: none;}
.btn1{ background: #1f8f07; color: #fff; padding: 18px 30px; border-radius: 6px;}
.btn1 a{ text-decoration: none; color: #fff; }
.btn2 a{ text-decoration: none; color: #fff; background: #1f5407; padding: 10px 30px; font-size: 22px }
.search2{ border-radius: 25px 0 0 25px; background: #f3f3f3; }
.search3{ border-radius: 0 25px 25px 0; background: #f3f3f3; padding: 4px 13px;
    min-height: 34px;  }
.search3 i{ color: #31af68; font-size: 14px;  }
.number1{ margin-bottom: 0; }
.pegination{ padding-left: 0; }
.pegination li{ list-style: none; display: inline; float: left; width: 34px; margin-right: 10px; }
	.pegination li a{ display: block;
		color: #1f5d07 !important;
		width: 100%;
		border: 1px solid #1f8f07;
		padding: 5px 0;
		text-align: center;
	   text-decoration: none;
	}
	
		.pegination li.selected{
			background-color: #1f8f07;
			color: #fff;
		}
		.pegination li a:focus, .pegination li a:hover{
			outline: none;
		}	
		.pegination li.selected a{
			color: #fff !important;
		}
.pagi1{ border: 1px solid #1f8f07; padding: 5px 0; }
.pegination li a:hover,.pegination li.active a{ background: #1f8f07; padding: 5px 0; color: #fff; }
.arrow1{ top: 40%; left: -4%;  background: none !important; opacity: 1 }
.arrow1 i{ background: #1f8f07; padding: 30px 15px; border-radius:0 6px 6px 0;}
.arrow2{ top: 40%; right: -4% !important; background: none !important; opacity: 1 }
.arrow2 i{ background: #1f8f07; padding: 30px 15px; border-radius: 6px 0 0 6px; margin-right:2px;}
.tab1 a{ color: #8d8d8d; font-weight: 500 !important; padding-right: 30px !important; font-size: 18px; border-bottom: 3px solid transparent !important;  }
.tab1 a:active{ color: #293341; font-weight: bold !important; }
.sideimg{ min-height: 294px; }
.cate-items{ padding: 0 0 0 10px;  }
.cate-items img{
	max-width: 126px;
    margin: 0 auto;
}
.cate-items p{
	line-height: 1.2em;
    
    height: 35px;
}
.emp-space{height: 30px;}
.cate-items a{ font-size: 14px; text-decoration: none; color: #000; }
.cate-items li{ list-style: none; display: inline; width: 11%; float: left; padding: 5px; }
.cate-items .cate-item{ border: 1px solid #cbcec9; border-radius: 6px; display:flex; flex-direction: column; justify-content: space-between;}
.cate-items .cate-item:hover{ border: 1px solid #048b36; border-radius: 6px;}
.cate-item{ background: #fff; padding: 1px 3px; min-height: 197px; }
.cate-name{ background: #c5e0e7; min-height: 230px; padding: 10px; width: 85%; }
.cate-name a{ color: #000; text-decoration: none; font-size: 14px; }
.cate1{ padding: 10px; border:1px solid #cbcdc8; border-radius: 6px; min-height:320px;}
.cate1:hover{border:1px solid #676c63;}

.cate1 p{ margin: 0; font-size: 14px;}
.cate1 a{ text-decoration: none; color: #000; font-size: 14px; }
.cate-text1{ color: #ccc; font-size: 14px;}
.cate-text2{ color: #ccc; font-size: 14px; text-decoration: line-through;}
.vmore1{ float: right; margin-right: -35px }
.vmore1 a{ text-decoration: none; font-size: 14px; }
.cate-name2{ background: #ffecc4; }
.searching{ background: #f3f3f3; font-size: 14px; }
.caret{ color: #31af68; }
#search_concept{ font-size: 14px !important; }
.offer{ padding-top: 5px; padding-bottom: 15px; }
.offer_banner1{ width: 98%; border:1px solid #cbcdc8;}
.cate_offer1{ width: 83%; }
.hr1{ border-top:2px solid  #adacac !important; }
.myCarousel1{ border: 2px solid #dedcdd !important; padding-bottom: 25px; border-radius: 6px;}

.myCarousel1 .owl-nav .owl-prev, .myCarousel1 .owl-nav .owl-next{
	position: absolute;
	top:calc(50% - 37px);
	background: #1f8f07 !important;
    padding: 30px 15px !important;
    border-radius: 6px 0 0 6px;
    margin-right: 2px;

}
.myCarousel1 .owl-nav .owl-prev{
	left: 0;
}
.myCarousel1 .owl-nav .owl-next{
	right: 0px;
}

.myCarousel1 .owl-dots button span {
    border: 1px solid #1f8f07;
    width: 18px !important;
    height: 18px !important;
	margin: 3px 5px !important;
    background: rgba(0,0,0,0);
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}
.myCarousel1 .owl-dots button.active span {
	border: 1px solid #1f8f07 !important;
    background: #1f8f07 !important;

}
.myCarousel1 .owl-nav button{
	margin: 0px !important;
}
.myCarousel1 .owl-nav button span i{
	background: #1f8f07;
    padding: 30px 15px;
    border-radius: 6px 0 0 6px;
    margin-right: 2px;
    color: #fff;
}
.tab-sec .tab1{
	padding-left: 0px;
}
.tab-sec .tab1 li{
	font-size: 18px;
	padding-right: 30px !important;
	padding: 10px 15px;
	line-height: 1.42857143;
	color: #8d8d8d;
}
.react-tabs__tab{
	border: none !important;
}
.react-tabs__tab:focus:after{
	content: none !important;
}
.tab-sec .tab1 li:hover{
    border-radius: 6px 6px 0 0;
	color: #555;
	border-bottom: 3px solid #1f8e07 !important;
}
.tab-sec .tab1 .react-tabs__tab--selected{
	border: none !important;
    background: #ebe3e3;
    border-radius: 6px 6px 0 0;
	color: #555;
	border-bottom: 3px solid #1f8e07 !important;
}

.ft-unset select{
	padding:6px 29px 6px 12px;
    border: none;
    height: 34px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 14px;
    background: #f3f3f3;
    color: #555;
	outline: none;
}
.ft-unset .caret{
	position: absolute;
    z-index: 1000;
    top: 16px;
    right: 5px;
}
.ft-unset select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}
.carousel-indicators1{ bottom: 1% !important; }
.carousel-indicators1 li{ border: 1px solid #1f8f07; width: 18px; height: 18px; }
.carousel-indicators1 li.active{ border: 1px solid #1f8f07; background: #1f8f07;width: 18px; height: 18px; }
.carousel-indicators1 img{ min-height: 400px; }

.tab1 .active a,.tab1 a:hover{ background:none !important;border-bottom: 3px solid #1f8e07 !important; border-top: none !important; border-right: none !important;border-left: none !important;}
.tab1 .active{ border: none !important; background:#ebe3e3; border-radius:6px 6px 0 0;}
.tab1{ border: none; }
.categories{ padding-top: 15px; }
.cateoffer{ padding-top: 60px; }
.w-100{ width: 100%; }
.cateoffer2{ padding-top: 30px }
.borderimg{border:1px solid #cbcdc8;}
.bank{ padding-bottom: 17px; }
.blankoffer{ padding-bottom: 74px }
.bordertop{ border-top:1px solid #adacac; }
.copyright{ font-size: 15px; }
.shortdesc td{ border: none !important; font-size: 20px; }
.shortdesc td span{ color: #23232c }
.related-heading{font-size: 30px; color: #1f5d07;}
.custom-panel{ border: none; margin-bottom: 0; box-shadow: none; }
.custom-panel .panel-heading{ background: none; border: none; box-shadow: none; padding:10px 0; text-decoration: none; }
.custom-panel .panel-heading i{ float:right; }
.custom-panel .panel-title{ font-size: 14px; font-weight: 500; }
.custom-panel .panel-title a{ text-decoration: none; }
.productlists{ padding: 0; margin-left: -7px }
.productlists li{ width:20%; float:left; list-style: none; padding:0 7px; margin-bottom: 16px }
.subsubcategory li a{
  font-size: 13px !important;
  color: #6f7178;
}
.mtml{
	margin-top: 12px;
    margin-right: 15px;
}
.mw-200{
	max-width: 150px;
	text-align: center;
}
.mw-350{
	padding-top: 15px;
	max-width: 350px;
}
.hrlist{margin-bottom: 8px; margin-top:4px; border-top:1px solid #1f8e07;}
.listprice{font-size: 14px; color: #293341;}
.text-green{ color: #1f8e07 }
.text-green:hover{ color: #1f8e07; text-decoration: none; }
.productlisting{padding-top: 35px; padding-bottom: 60px}
.fa-plus1{ background: url('../../../public/images/plus.png'); height: 9px; width: 9px; background-size: 100% 100%; margin-top: 3px; }
.fa-minus1{ background: url('../../../public/images/minus.png'); height: 9px; width: 9px; background-size: 100% 100%; margin-top: 3px; }
.moremenu li a{ display: block; padding: 7px 10px }
.moremenu li a:hover{ background: #31af68; color: #fff; }
.accordian .panel-title{
	cursor: pointer;
}
/* Start 23.02.2022*/
.custom-select{ height: 25px; margin:3px 0; padding: 1px;text-align: center; }
.padding0{ padding: 0 }
.qtyfirstdiv {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 4px;
}
.mt0{
	margin-top: 0px !important;
}
.qtybtn{ color: #000; background: #fbcb2d; border: none; font-size: 10px; text-align: center; width: 100%; min-width: 42px; max-width: 42px; border-radius:3px; }
.qtybtn2{ color: #000; background: #fbcb2d; border: none; font-size: 10px; text-align: center; width: 100%; width: 100%; border-radius:3px; }
.cpadding1{  padding-right: 0; padding-left: 0; margin-top: 3px; }
.cpadding2{  padding-right: 8px; padding-left: 10px;}
.cpadding3{ padding-right: 0; padding-left: 0; margin-top: 2px; }
.btn-number{ background:#fbcb2d; color: #000; height: 25px; padding:0 5px !important; line-height: 0  }
.input-number{ height: 25px; padding:1px; font-size: 12px; text-align: center; }
.qtyseconddiv{ display: none; margin-top: 5px; }
/* End Start 23.02.2022 */
.accordion button{
	text-decoration: none;
	border: none;
}
.accordion button:hover, .accordion button.collapsed{
	text-decoration: none;
}
.accordion button:focus{
 outline: none;
 box-shadow: none !important;
}
.career-accordion h2{
	display: flex;
	align-items: center;
}
.career-accordion h2 button.collapsed + i{
	transform: rotate(0deg);
}
.career-accordion h2 button + i{
	transform: rotate(180deg);

}
.career-accordion h2 i {
	font-size: 20px;
    margin-right: 11px;
    position: relative !important;
    top: 0px !important;
    height: unset !important;
}
.career h4{
	font-size: 24px !important;
    letter-spacing: 0.5px;
}
.career .btn-link{
	color: #000;
	font-size: 16px;
	text-decoration: none;
}
.career .card-body h5{
	font-size: 16px;
}
.career .card-body p{
	font-size: 14px;
}
.career .card-header{
	padding: 7px !important;
}
@media only screen and (max-width: 767px) {
	
	.pl-0{
		padding-left: 15px !important;
	}
	.mmt-10{
		margin-top: 10px;
	}
	.payUsing{
		display: block;
	}
	.visible-xs{
		display: block;
	}
	.hidden-xs {
		display: none!important;
	}
	.vmoreAb{
		position: absolute;
		position: absolute;
        right: 14px;
        top: 24px;
	}
	.dp-toggle{
		display: block;
	}
	.app1 li {
		display: inline-block;
		float: none;
	}
	.app1 li img{
		width: auto;
	}
	.logolink{text-align: center;}
	.pl-0{ padding-left: 15px }
	.hero{ padding-top: 20px }
	.owl-carousel1 .owl-prev{ top:57%; right: 10%}
	.owl-carousel1 .owl-next{ top:57%; right: 2%; }
	.sideimg{ margin-top: -10px }
	.owl-carousel2 .owl-next,.owl-carousel2 .owl-prev{ top:31%; }
	.categories{ padding-top: 0 }
	.cate-items li{ width: 32.5% }
	.cate-item{ padding: 1px 5px; min-height: 170px }
	.cateoffer{ padding-top: 20px }
	.vmore1{ margin-right: 65px}
	.w-100.borderimg{ margin-top: 30px; margin-bottom: 30px }
	.car2,.car3{ margin-top: 63px }
	.cateoffer2{ padding-top: 0 }
	.o2{ margin-top: 20px }
	.owl-carousel22 .owl-next,.owl-carousel22 .owl-prev{ top:26%; }
	.owl-carousel22{ margin-top: 20px }
	.bank{ padding-bottom: 0 }
	.blankoffer{ padding-bottom: 10px }
	.blankoffer img{ margin-bottom: 20px }
	.pay1 li{width:12%;}
	.p-0{ padding-left: 15px !important; padding-right: 15px !important; }
	.menu2{display: none;position: absolute;z-index: 9;background: #ccc;top: 296px; width: 100%}
	.menu2.show{display: block}
	.menu2 li{ display: block; width: 100%; padding-left: 20px }
	.navbar-toggle .icon-bar{ background: #1f8f07 }
	.productlists li{ width: 50% }
	.productlists{ margin-left: 0; margin-top: 30px }
	/* .sidebar1{ display: none } */
	.sidebar1{margin-top: 15px; display: none;}
	.sidebar1.show{
		display: block;
	}

	.h3-xs{ margin:0; }
	.catmenu{ margin-top: -4px; margin-right: 0;background: #1f8f07  }
	.catmenu .icon-bar{ background: #fff }
	.pegination{ padding-left: 15px }
	.pegination li{ width: 12%; }
	.product-title{ margin-top: 30px }
	.tab1 li{ width: 100% }
	.products li{ width: 50%; padding: 0 1px; margin-bottom: 30px }
	.products li:first-child{ margin-left: 0 }
	.mt20-xs{ margin-top: 20px }
	.app1{width: 84%;}

}

@media (min-width: 768px) and (max-width: 1024px){

	.logolink{ text-align: center; }
	.sideimg{min-height: 170px}
	.owl-carousel1 .owl-prev{ top:71%; right: 7%}
	.owl-carousel1 .owl-next{ top:71%; right: 2%; }
	.cate-items li{ width: 25%; }
	.cate-items img{ display: block; margin:0 auto; }
	.vmore1{ margin-right: 75px; }
	.productlists li{ padding: 0 3px; width: 33.3%; }
	.pegination li{ width: 7%; }
	.pl-0{ padding-left: 15px; }
	.p-0{ padding-right: 15px !important; padding-left: 15px !important; }
	.menu2{ display: none; }
	.menu2{display: none;position: absolute;z-index: 9;background: #ccc;top: 296px; width: 100%}
	.menu2 li{ display: block; width: 100%; padding-left: 20px }
	.navbar-toggle .icon-bar{ background: #1f8f07 }
	.navbar-toggle{ display: block }
	.tab1 a{ padding-right: 3px !important; font-size: 13px; }
	.products li{ width: 33.3% }
	.mt20-sm{ margin-top: 20px }
	.products li:first-child{ margin-left: 0 }
	.app1 li{ width: 50%; margin-top: 2px; }

}